<template>
    <div class="indexbox">
        <div class="a-flex-rsbc">
            <div class="a-fs-16 a-fw-500 a-ff-pmp">各地区充电桩安装/拆除情况</div>
            <el-date-picker v-model="time" type="month" placeholder="选择月" @change="getTimeValue" :editable="false"
                :picker-options="pickerNormalOptions"></el-date-picker>
        </div>
        <div ref="azEcharts" style="width: 100%;height: 259px;margin-top:14px;"></div>


    </div>
</template>
<script>
import util from '../../../utils/util.js'
export default {
    data() {
        return {
            time: new Date(),
            pickerNormalOptions: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now()
                }
            },
            startTime: '',
            endTime: '',
            detailList: [[
                'product', '安装量', '拆除量'
            ],
            ['河北省', 0, 0],
            ['吉林省', 0, 0],
            ['海南省', 0, 0],
            ['天津', 0, 0],
            ['河南省', 0, 0],
            ['湖北省', 0, 0],
            ['辽宁省', 0, 0]
            ],
        }
    },
    methods: {
        drawChart(data = []) {
            var chartDom = this.$refs['azEcharts']
            var option;

            if (data.length == 0) {
                data = this.detailList

            }

            option = {
                tooltip: {},
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    right: "2%",
                    itemGap: 40
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '2%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    alignTicks: true,
                    axisLine: {
                        lineStyle: {
                            color: '#DDDDDD'
                        }
                    },
                    axisTick: {
                        // alignWithLabel: true,
                        lineStyle: {
                            color: '#DDDDDD'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        color: '#999999',
                        show: true,
                        fontSize: 12,
                        formatter: function (value, index) {
                            let r = []
                            if (value.length > 3) {
                                for (let i = 0, len = value.length; i < len; i += 3) {
                                    r.push(value.slice(i, i + 3))

                                }
                                let a = r.join('\n')
                                value = eval('`' + a + '`')

                            }
                            return value

                        }
                    },


                    // data: xAxis
                },
                dataset: {
                    source: data
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#EEEEEE',
                            type: 'dashed'
                        }
                    },
                },
                series: [
                    {
                        type: 'bar',
                        barWidth: 10,
                        label: {
                            normal: {
                                show: true,
                                position: 'top',
                                textStyle: {
                                    color: '#606366',
                                }

                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#6699FF',
                                barBorderRadius: [4, 4, 0, 0],
                            }
                        },
                        barGap: '60%'
                    },
                    {
                        type: 'bar',
                        barWidth: 10,
                        label: {
                            normal: {
                                show: true,
                                position: 'top',
                                textStyle: {
                                    color: '#606366',
                                }

                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#FFA900',
                                barBorderRadius: [4, 4, 0, 0],
                            }
                        },
                        barCategoryGap: 50,
                    }

                ],
            };
            if (!!chartDom) {
                var myChart = this.$echarts.init(chartDom);

                myChart.setOption(option, true);
            }
            window.addEventListener("resize", () => {
                if (myChart) {
                    myChart.resize()
                }
            })


        },
        getTimeValue(time) {
            var date = time ? time : new Date()
            var y = date.getFullYear(), m = date.getMonth(), d = date.getDate()

            if (!time && d < 20) {
                m = m - 1
                this.time = new Date(y, m, 1)
            }
            this.startTime = util.easyformatDate(new Date(y, m, 1))

            this.endTime = util.easyformatDate(new Date(y, m + 1, 0));
            this.getInfo()


        },
        getInfo() {
            this.$Axios._post({
                url: this.$Config.apiUrl.chargingDeviceInstall,
                showLoading: false,
                params: {
                    "startTime": this.startTime,
                    "endTime": this.endTime
                }
            }).then((res) => {
                console.log(res)
                let that = this
                if (res.result.code == 0) {
                    let list = []
                    if (res.result.data.length) {


                        let alist = [
                            'product', '安装量', '拆除量'
                        ]

                        res.result.data.map((item, index) => {
                            let a = [item.province, item.installNum, item.unInstallNum]
                            list.push(a)

                        })
                        list.unshift(alist);



                    }
                    this.drawChart(list)
                } else {
                    this.$message.error(res.result.msg)
                }

            }).catch(error => {
                this.$message.error(error)
            })
        }

    },

    mounted() {

        this.getTimeValue()
        //this.getInfo()

    }

}
</script>
<style lang="scss" scoped>
</style>