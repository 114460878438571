<template>
    <div class="indexbox">
        <div class="a-flex-rsbc">
            <div class="a-fs-16 a-fw-500 a-ff-pmp">{{ active ? '插槽使用情况' : '设备使用情况' }}</div>
            <div class="a-c-second a-fs-14">
                <span class="a-cursor-p" :class="{ 'a-c-master a-fw-700': active == 0 }"
                    @click="handlerChange(0)">设备</span> <span class="a-plr-10 a-c-ccc">|</span> <span class="a-cursor-p"
                    :class="[{ 'a-c-master a-fw-700': active == 1 }]" @click="handlerChange(1)">插槽</span>
            </div>
        </div>
        <div class="a-w-100 a-h-100" v-if="active == 0">
            <div ref="echartsRef" style="width:100%;height:176px;margin-top:63px;"></div>
        </div>
        <div v-if="active == 1" class="a-flex-csbfs a-fs-14 cachaobox a-w-100 a-bg-white">
            <div class="a-flex-rfsc cachaobox-top">
                <div class="a-flex-cfsfs">
                    <span class="a-c-normal">插槽总数</span>
                    <span class="a-fw-700 a-fs-24 a-ff-db a-mt-8">{{ slotDetail.totalNum }}</span>
                </div>
                <div class="a-flex-cfsc">
                    <div><span class="round round-green"></span><span class="a-ml-08 a-c-normal">使用中</span></div>
                    <span class="a-fw-700 a-fs-24 a-ff-db a-mt-8">{{ slotDetail.usingNum }}</span>
                </div>
            </div>
            <div class="cachaobox-bottom a-flex-cfsfs a-w-100 a-mt-45">
                <span class="a-c-normal">插槽使用率</span>
                <div class="a-mt-16 a-flex-rsbc a-w-100 a-plr-25">
                    <div ref="echartsCachaoLeft" style="width:68px;height:110px;"></div>
                    <div ref="echartsCachaoCenter" style="width:68px;height:110px;"></div>
                    <div ref="echartsCachaoRight" style="width:68px;height:110px;"></div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            active: 0,
            echarts: null,
            deviceInfoList: null,
            totalNum: 0,
            slotDetail: {
                totalNum: 0,
                usingNum: 0
            }
        }
    },
    methods: {
        drawChart(xAxia = 0, data = []) {
            var chartDom = this.$refs['echartsRef'];
            var option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                color: ['#29CC29', '#007AFF', '#FF3B30', '#FFA900'],
                legend: {
                    top: '20%',
                    right: '65%',
                    orient: 'vertical',
                    width: '120',
                    padding: [0, 0, 0, 0],
                    itemGap: 18,
                    itemWidth: 8,
                    itemHeight: 8,
                    icon: 'circle',
                    formatter: function (params) {
                        let a;
                        let b;
                        data.forEach((item, index) => {
                            if (item.name == params) {
                                if (item.name == '使用中') {
                                    a = "${item.name}    ${item.value}"
                                } else {
                                    a = "${item.name}       ${item.value}"
                                }

                                b = eval('`' + a + '`')
                            }
                        })

                        return b
                    }
                },
                series: [
                    {
                        name: '设备使用情况',
                        type: 'pie',
                        radius: ['67%', '90%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 6,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            normal: {
                                show: true,
                                position: 'center',
                                textBorderWidth: 0,
                                formatter: [
                                    '{a|设备总数}',
                                    `{b|${xAxia}}`

                                ].join('\n'),
                                rich: {
                                    a: {
                                        color: '#606366',
                                        fontSize: 14,
                                        lineHeight: 26
                                    },
                                    b: {
                                        color: '#303133',
                                        fontSize: 24,
                                        fontWeight: 700,
                                        lineHeight: 35

                                    }
                                }
                            }
                        },

                        center: ['70%', '50%'],
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '12',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data
                    }
                ]
            }
            if (!!chartDom) {
                this.echarts = this.$echarts.init(chartDom);
                this.echarts.setOption(option, true);
            }

            window.addEventListener("resize", () => {
                if (this.echarts) {
                    this.echarts.resize()
                }
            })

        },



        handlerChange(index) {
            this.active = index
            if (index == 0) {

                setTimeout(() => {
                    this.drawChart(this.totalNum, this.deviceInfoList)
                }, 200)

            } else {
                this.getSlotInfo();
                if (this.echarts) {
                    this.echarts.dispose();
                }
                setTimeout(() => {
                    this.drawChartCachao(this.$refs['echartsCachaoLeft'], '今日', Math.round(this.slotDetail.todayUsePercent) || 0)
                    this.drawChartCachao(this.$refs['echartsCachaoCenter'], '昨日', Math.round(this.slotDetail.yesterdayUsePercent) || 0)
                    this.drawChartCachao(this.$refs['echartsCachaoRight'], '本周', Math.round(this.slotDetail.weekUsePercent) || 0)
                }, 200)
            }
        },
        drawChartCachao(chartDom, name, value) {
            // var chartDom = this.$refs['echartsCachaoLeft'];

            var option;
            const gaugeData = [
                {
                    value: value,
                    name: name,
                    title: {
                        offsetCenter: ['0%', '165%'],
                        color: '#606366'
                    },
                    detail: {
                        valueAnimation: true,
                        offsetCenter: ['0%', '0%'],
                        color: '#303133'
                    }
                }
            ];
            option = {
                grid: {
                    top: 0,
                    left: 0
                },
                series: [
                    {
                        type: 'gauge',
                        radius: '98%',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '35%'],
                        pointer: {
                            show: false
                        },
                        progress: {
                            show: true,
                            overlap: false,
                            roundCap: true,
                            clip: false,
                            itemStyle: {
                                borderWidth: 0,
                                borderColor: '#464646'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 6,
                                color: [[0, '#EBF0F5'], [1, '#EBF0F5']]
                            }
                        },
                        splitLine: {
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            distance: 50
                        },
                        data: gaugeData,
                        title: {
                            fontSize: 14,
                            color: '#606366'
                        },
                        detail: {
                            width: 38,
                            height: 24,
                            fontSize: 16,
                            color: '#303133',
                            borderWidth: 0,
                            fontWeight: 'bold',
                            formatter: '{value}%'
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: 'rgba(0, 122, 255, 0)' },
                                { offset: 1, color: 'rgba(0, 122, 255, 1)' }
                            ])
                        },
                    }
                ]
            }
            if (!!chartDom) {

                this.echarts = this.$echarts.init(chartDom);
                this.echarts.setOption(option, true);

            }




        },
        getDeviceInfo() {
            this.$Axios._post({
                url: this.$Config.apiUrl.deviceUsedInfo,
                showLoading: false,
                params: {
                    "startTime": this.startTime,
                    "endTime": this.endTime
                }
            }).then((res) => {
                console.log(res)
                let that = this
                if (res.result.code == 0) {
                    if (res.result.data) {
                        if (res.result.data[0]) {
                            var a = {
                                onlineNum: '在线',
                                offlineNum: '离线',
                                errorNum: '异常',
                                usingNum: '使用中'
                            }
                            var b = []
                            for (let i in a) {
                                let item = {
                                    value: res.result.data[0][i],
                                    name: a[i]
                                }
                                b.push(item)
                            }
                            console.log(JSON.stringify(b));
                            this.deviceInfoList = b
                            this.totalNum = res.result.data[0].totalNum
                        }

                        this.drawChart(this.totalNum, this.deviceInfoList)

                    }

                } else {

                    this.$message.error(res.result.msg)
                }

            }).catch(error => {

                this.$message.error(error)
            })
        },
        getSlotInfo() {
            this.$Axios._post({
                url: this.$Config.apiUrl.slotUsedInfo,
                showLoading: false,
                params: {
                    "startTime": this.startTime,
                    "endTime": this.endTime
                }
            }).then((res) => {
                console.log(res)
                let that = this
                if (res.result.code == 0) {
                    if (res.result.data && res.result.data[0]) {
                        this.slotDetail = res.result.data[0];

                    }

                } else {
                    this.$message.error(res.result.msg)
                }

            }).catch(error => {
                this.$message.error(error)
            })
        }

    },

    mounted() {
        this.getDeviceInfo()
        // this.drawChart([], this.data)

    }
}
</script>
<style lang="scss" scoped>
.round {
    border-radius: 100%;
    height: 8px;
    width: 8px;
    display: inline-block;

    &-green {
        background: #29CC29;
    }
}

.cachaobox {
    margin-top: 32px;

    &-top {
        >div {
            &:nth-of-type(2) {
                margin-left: 126px;
            }
        }
    }
}
</style>