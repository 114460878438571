<template>
    <div class="app-body home">
        <indexSjzxVue></indexSjzxVue>
        <div class="a-flex-rsbfs a-mt-16">
            <index-yye-pie 
                :params="{
                    companyId: company.id
                }"
                :infoType="'business'"
                ref="yyePie" 
                class="a-flex-1"></index-yye-pie>
        </div>
        <!-- <div class="a-flex-rsbfs a-mt-16">
            <indexYyeVue class="a-flex-1"></indexYyeVue>
        </div> -->
        <div class="a-flex-rsbfs a-mt-16">
            <index-income class="a-flex-1"></index-income>
        </div>
        <div class="a-flex-rsbfs a-mt-16">
            <index-park class="a-flex-1"></index-park>
        </div>
        <div class="a-flex-rsbfs a-mt-16 home-item">
            <indexGdqcdzazVue></indexGdqcdzazVue>
            <indexSbsyqkVue></indexSbsyqkVue>
        </div>
        <div class="a-flex-rsbfs a-mt-16 home-item">
            <indexMapVue></indexMapVue>
            <indexFastVue></indexFastVue>
        </div>
        <div class="a-flex-rsbfs a-mt-16 home-item">
            <indexTodolistVue></indexTodolistVue>
            <indexNewsVue></indexNewsVue>
        </div>
    </div>
</template>
<script>
import indexSjzxVue from "./child/index-sjzx.vue";
import indexYyeVue from "./child/index-yye.vue";
import indexByshyyephVue from "./child/index-byshyyeph.vue";
import indexGdqcdzazVue from "./child/index-gdqcdzaz.vue";
import indexSbsyqkVue from "./child/index-sbsyqk.vue";
import indexMapVue from "./child/index-map.vue";
import indexFastVue from "./child/index-fast.vue";
import indexTodolistVue from "./child/index-todolist.vue";
import indexNewsVue from "./child/index-news.vue";
import IndexIncome from './child/index-income.vue';
import IndexPark from './child/index-park.vue';
import IndexYyePie from './child/index-yye-pie.vue';
import { mapState, mapActions } from 'vuex';
import Utils from "../../utils/util.js";
export default {
    components: {
        indexSjzxVue,
        indexYyeVue,
        indexByshyyephVue,
        indexGdqcdzazVue,
        indexSbsyqkVue,
        indexMapVue,
        indexFastVue,
        indexTodolistVue,
        indexNewsVue,
        IndexIncome,
        IndexPark,
        IndexYyePie,
    },
    data() {
        return {};
    },
    computed:{
        ...mapState({
            company: state => state.company.company
        })
    },
    mounted() {
        // this.$refs['yyePie'].getIncomeDistri()
        // this.$refs['yyePie'].getTransitionDistri()
    },
};
</script>
<style lang="scss" scoped>
.home {
    margin-top: 16px;
    overflow-y: auto;
    padding-bottom: 32px;

    &-item {
        height: 344px;
        // overflow-y: auto;

        > * {
            height: 100%;
            // overflow-y: auto;

            &:nth-of-type(1) {
                width: 66%;
            }

            &:nth-of-type(2) {
                width: 33%;
            }
        }
    }
}

/deep/ .indexbox {
    padding: 20px 24px;
    background: #fff;
    border-radius: 4px;
}
</style>
