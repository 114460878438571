<template>
    <div class="indexbox">
        <div class="a-flex-rsbfs">
            <div class="a-fs-16 a-fw-500 a-w-100 a-ff-pmp">数据中心</div>
            <le-date-range 
                class="timePicker"
                ref="dateRange111" 
                :pickerOptions="pickerOptions" 
                :minDate.sync="startTime" 
                :maxDate.sync="endTime" 
                :defaultTime="['00:00:00','23:59:59']"
                valueFormat="yyyy-MM-dd" />
        </div>
        <div class="a-flex-rfsc indexbox-top">
            <div class="indexbox-top-item a-flex-rsbc">
                <div class="a-flex-csbfs a-h-100 a-ml-24 a-flex-1" >
                    <span class="a-c-normal a-fs-14">总营业额(元)</span>
                    <div class="a-fs-18 a-fw-700 a-mtb-12 a-w-100" style="word-wrap:break-word">
                        &yen;<span class="a-fs-36 a-ml-5 a-ff-psp" style="line-height: 32px;">{{ util.numFormat(dataCenterInfo.transactionAmount) }}</span>
                    </div>
                </div>
            </div>
            <div class="a-flex-2 indexbox-top-item a-flex-rsbc ">
                <div ref="echartsRef" style="height:150px;" class="a-flex-1"></div>
            </div>
        </div>
        <div class="a-flex-rfsc a-pt-20">
            <div class="indexbox-top-item a-flex-rsbc">
                <div class="a-flex-csbfs a-h-100 a-ml-24">
                    <span class="a-c-normal a-fs-14">总订单量(笔)</span>
                    <div class="a-fs-18 a-fw-700 a-mtb-12">
                        <span class="a-fs-24 a-ff-db">{{ dataCenterInfo.orderNum || 0 }}</span>
                    </div>
                </div>
                <div class="borderline"></div>
            </div>
            <div class="indexbox-top-item a-flex-rsbc">
                <div class="a-flex-csbfs a-h-100 a-ml-24">
                    <span class="a-c-normal a-fs-14">退款金额(元)</span>
                    <div class="a-fs-18 a-fw-700 a-mtb-12">
                        &yen;<span class="a-fs-24 a-ml-5 a-ff-db">{{ util.numFormat(dataCenterInfo.refundAmount) }}</span>
                    </div>
                </div>
                <div class="borderline"></div>
            </div>
            <div class="indexbox-top-item a-flex-rsbc">
                <div class="a-flex-csbfs a-h-100 a-ml-24">
                    <span class="a-c-normal a-fs-14">总充电桩(台)/插槽数(个)</span>
                    <div class="a-fs-18 a-fw-700 a-mtb-12">
                        <span class="a-fs-24 a-ff-db">{{ dataCenterInfo.allDeviceNum || 0 }}</span>
                        <span class="a-fw-700 a-fs-24 a-ml-08">/</span>
                        <span class="a-fs-24 a-ff-db a-ml-5">{{ dataCenterInfo.allSlotNum || 0 }}</span>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import util from '../../../utils/util.js'
import leDateChangeTimeIndex from '../../components/form/le-date-changeTime-index.vue'
export default {
    components: {
        'le-date-changeTime-index': leDateChangeTimeIndex
    },
    data() {
        return {
            util: util,
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    if (maxDate) {
                        this.choiceDate = ''
                    }
                },
                disabledDate: (time) => {
                    if (this.choiceDate) {
                        let one = 91 * 24 * 3600 * 1000
                        const minTime = this.choiceDate - one
                        const maxTime = this.choiceDate + one
                        return time.getTime() < minTime || time.getTime() > maxTime
                    }else{
                        return ''
                    }
                },
            },
            startTime: this.$getDay.getTodayBeforeDays(0),
            endTime: this.$getDay.getTodayBeforeDays(0),
            echarts: null,
            echartsRight: null,
            payTypeList: null,
            dataCenterInfo: '',
            data: [{ value: 0, name: '余额支付' },
                { value: 0, name: '微信支付' },
                { value: 0, name: '云闪付' },
                { value: 0, name: '月卡支付' },
                { value: 0, name: '刷卡支付' },
                { value: 0, name: '其他方式' }],
			timeChange:'',
        }
    },
    created() {

    },
    mounted() {
        this.handlerPayType()
        this.getData();
    },
    watch: {
        dateRange: {
            handler(val) {
                this.getData(); // 获取基础数据
                this.handlerPayType() // 获取支付渠道数据
            },
        },
		
    },
    computed:{
        dateRange () {
            return this.startTime + this.endTime
        }
    },
    methods: {
		getData(){
			this.$Axios._post({
                url: this.$Config.apiUrl.getIndexDataCenter,
                showLoading: false,
                params: {
                    startDay: this.startTime,
                    endDay: this.endTime
                }
            }).then((res) => {
                if(res.result.code == 0){
                    this.dataCenterInfo = res.result.data
                }
            }).catch(error => {

            })
		},
        //获取支付方式数据
        handlerPayType() {
            this.$Axios._post({
                url: this.$Config.apiUrl.getIndexDataCenterPayType,
                showLoading: false,
                params: {
                    startDay: this.startTime,
                    endDay: this.endTime
                }
            }).then((res) => {
                if (res.result.code == 0) {
                    if (res.result.data) {
                        this.payTypeList = res.result.data.map((item, index) => {
                            return {
                                name: item.payType,
                                value: item.payAmount?Number(item.payAmount):0
                            }
                        })
                        this.drawChart([], this.payTypeList&&this.payTypeList.length?this.payTypeList:[{ value: 0, name: '其他方式' }])
                    }
                }else {
                    this.drawChart([], [{ value: 0, name: '其他方式' }])
                    this.$message.error(res.result.msg)
                }

            }).catch(error => {
                this.drawChart([], [{ value: 0, name: '其他方式' }])
                this.$message.error(error)
            })
        },
		drawChart(xAxis = [], data = []) {
            var chartDom = this.$refs['echartsRef'];
            var option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                color: ['#3BA1FF', '#4DCB73', '#FF3030', '#FF9C00', '#FFD700','#3ba272'],
                legend: {
                    top: 'middle',
                    height: 'auto',
                    left: 'left',
                    x: 'left',
                    orient: 'vertical',
                    width: '120',
                    itemGap: 12,
                    itemWidth: 8,
                    itemHeight: 8,
                    icon: 'circle',
                    textStyle: {
                        fontSize: 12,
                    },
                    formatter: function (params) {
                        let a;
                        let b = []
                        let c;
                        let total;
                        data.forEach((item, index) => {
                            b.push(item.value)
                        })
                        if (b.length) {
                            total = b.reduce((val, oldVal) => parseInt(val) + parseInt(oldVal))
                        } else {
                            total = 0
                        }
                        data.forEach((item, index) => {
                            if (item.name == params) {
                                if (total == 0) {
                                    a = "${item.name}  0%"
                                } else {
                                    a = "${item.name}   ${((item.value / total) * 100).toFixed(2)}%"
                                }
                                c = eval('`' + a + '`')
                            }

                        })
                        return c
                    }
                },
                series: [
                    {
                        name: '营业额比例',
                        type: 'pie',
                        radius: ['57%', '90%'],
                        center: [(data.length > 6?410:220), '50%'],
                        itemStyle: {
                            borderRadius: 6,
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                show: false,
                                position: 'center',
                                textBorderWidth: 0,
                                formatter: function (params) {
                                    return params.data.name.split(' ')[0]
                                }
                            }
                        },

                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '12',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data.map(item => item = { ...item, 'value': item.value / 100 })
                    }
                ]
            }
            setTimeout(() => {
                this.echarts = this.$echarts.init(chartDom)
                this.echarts.setOption(option, true)
            }, 500)

            window.addEventListener("resize", () => {
                if (this.echarts) {
                    this.echarts.resize()
                }
            })
        },
        

    }
}
</script>
<style lang="scss" scoped>
.indexbox {
    &-top {
        margin-top: 20px;

        &-item {

            // &:nth-of-type(1) {
            //     width: 38%;
            // }

            // &:nth-of-type(2) {
            //     width: 19%;
            // }

            // &:nth-of-type(3) {
            //     width: 19%;
            // }

            // height: 96px;
            flex: 1;
            .colortip {
                width: 16px;
                height: 16px;
            }

            .borderline {
                width: 1px;
                height: 96px;
                border: 1px solid;
                border-image: linear-gradient(180deg, rgba(200, 200, 200, 0), rgba(225, 225, 225, 0.82), rgba(151, 151, 151, 0)) 1 1;
            }
        }
    }

    /deep/ .changeTime {
        >div {
            &:nth-of-type(2) {
                margin-left: 16px !important;
            }
        }
    }
    .a-flex-2{
        flex: 2;
    }
}
</style>