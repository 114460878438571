<template>
    <div class="indexbox" style="height: 100%; overflow: hidden">
        <div class="a-flex-rsbc">
            <div class="a-fs-16 a-fw-500 a-ff-pmp">
                我的待办 [
                <span class="a-c-blue a-fw-700">{{ todonum.totalNum }}</span>
                ]
            </div>
            <le-jumpto-detail url="/todolist/todolist-index">
                <span class="a-fs-14 a-c-normal a-cursor-p">查看更多</span>
            </le-jumpto-detail>
        </div>
        <div class="todolistbox a-fs-14">
            <el-row
                class="todolistbox-item"
                v-for="(item, index) in todoList"
                :key="index"
            >
                <el-col :span="13"
                    ><span
                        style="
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 100%;
                            display: inline-block;
                        "
                        >{{ item.content }}</span
                    >
                </el-col>
                <el-col :span="5" :offset="2"
                    ><span class="a-c-second">{{
                        item.createTimeText
                    }}</span></el-col
                >
                <el-col :span="2" :offset="2" style="text-align: right"
                    ><span
                        class="a-c-blue"
                        :class="
                            item.pcNotifyPage ? 'a-cursor-p' : 'a-default-p'
                        "
                        @click="handlerGoDetial(item)"
                        >去处理</span
                    >
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
export default {
    data() {
        return {
            todoList: [],
        };
    },
    computed: {
        ...mapState({
            todonum: (state) => state.todonum.todonum,
        }),
    },
    methods: {
        handlerGoDetial(item) {
            if (item.pcNotifyPage && item.pcNotifyPageParam) {
                this.$router.push({
                    path: item.pcNotifyPage,
                    query: JSON.parse(item.pcNotifyPageParam),
                });
            }
        },
        getTodoList() {
            this.$Axios
                ._post({
                    url: this.$Config.apiUrl.getWithDoneList,
                    showLoading: false,
                    params: {
                        pageNum: 1,
                        pageSize: 5,
                        keyType: "",
                        isDeal: 0,
                    },
                })
                .then((res) => {
                    console.log(res);
                    let that = this;
                    if (res.result.code == 0) {
                        if (res.result.data && res.result.data.list) {
                            this.todoList = res.result.data.list;
                        }
                    } else {
                        this.$message.error(res.result.msg);
                    }
                })
                .catch((error) => {
                    this.$message.error(error);
                });
        },
    },
    mounted() {
        this.getTodoList();
    },
};
</script>
<style lang="scss" scoped>
.todolistbox {
    margin-top: 24px;

    &-item {
        border-bottom: 1px solid #ebf0f5;
        padding: 13px 0;
        padding-right: 16px;

        // >div {
        //     >span {
        //         &:nth-of-type(1) {
        //             width: 551px;
        //         }
        //     }
        // }
    }
}
</style>
