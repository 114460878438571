<template>
    <div class="indexbox">
        <div class="a-flex-rsbc">
            <div class="a-fs-16 a-fw-500 a-ff-pmp">收益趋势</div>
            <le-date-range 
                class="timePicker"
                ref="dateRangeindex" 
                :pickerOptions="pickerOptions" 
                :minDate.sync="startTime" 
                :maxDate.sync="endTime" 
                :defaultTime="['00:00:00','23:59:59']"
                valueFormat="yyyy-MM-dd HH:mm:ss" />
        </div>
        <le-echarts-line :echartsObj="echartsObj" style="height:258px;width:100%;margin-top:14px;"></le-echarts-line>
    </div>
</template>
<script>
import util from '../../../utils/util.js'
export default {
    data() {
        return {
            choiceDate: '',
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    if (maxDate) {
                        this.choiceDate = ''
                    }
                },
                disabledDate: (time) => {
                    if (this.choiceDate) {
                        let one = 30 * 24 * 3600 * 1000
                        const minTime = this.choiceDate - one
                        const maxTime = this.choiceDate + one
                        return time.getTime() < minTime || time.getTime() > maxTime
                    }else{
                        return ''
                    }
                },
            },
            startTime: this.$getDay.getTodayBeforeDays(30) + ' 00:00:00',
            endTime: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
            echartsObj: {
                id: 'indexEcharts',
                xAxisData: ['2022-09-03', '2022-09-06', '2022-09-09', '2022-09-12', '2022-09-15', '2022-09-18', '2022-09-21', '2022-09-24', '2022-09-27', '2022-09-30',],
                seriesData0: [2000, 2800, 3200, 3200, 3000, 2900, 2800, 2200, 2500, 2700],
                seriesData1: [1900, 2600, 3000, 3100, 2800, 2700, 2600, 2100, 2400, 2600],
				seriesData2: [1900, 2600, 3000, 3100, 2800, 2700, 2600, 2100, 2400, 2600],
                seriesName0: '收益金额',
                seriesName1: '订单数量',
				seriesName2: '交易金额',
				seriesUnit0: '元',
				seriesUnit1: '笔',
				seriesUnit2: '元',
                echartsType: 'energyList',
                yName: "(元/笔/元)"
            },
        }
    },
    computed:{
        dateStr () {
            return this.startTime + this.endTime
        }
    },
    watch:{
        dateStr:{
            handler (val) {
                this.handlerGetStat()
            }
        }  
    },
    methods: {
        handlerGetStat() {
            this.$Axios._post({
                url: this.$Config.apiUrl.getAnalysisIncomeTrendBusiness,
                showLoading: false,
                params: {
                    endDay: this.endTime,
                    startDay: this.startTime
                }
            }).then((res) => {
                // console.log(res)
                let that = this
                if (res.result.code == 0) {
                    if (res.result.data) {
                        let dateArr
                        if(res.result.data.incomeData.length){
                            dateArr = res.result.data.incomeData
                        }else{
                            dateArr = this.$getDay.enumerateDaysBetweenDates(this.startTime,this.endTime).map(item=>{
                                return {
                                    day: item,
                                    incomeAmount: 0,
                                    orderNum: 0,
                                    transactionAmount: 0
                                }
                            })
                        }
                        that.echartsObj.xAxisData = []
                        that.echartsObj.seriesData0 = []
                        that.echartsObj.seriesData1 = []
						that.echartsObj.seriesData2 = []
                        dateArr.map((item, index) => {
                            that.echartsObj.xAxisData.push(item.day)
                            that.echartsObj.seriesData0.push(item.incomeAmount?(item.incomeAmount/100).toFixed(2):0)
                            that.echartsObj.seriesData1.push(item.orderNum || 0)
							that.echartsObj.seriesData2.push(item.transactionAmount?(item.transactionAmount/100).toFixed(2):0)
                        })

                    }
                } else {
                    this.$message.error(res.result.msg)
                }

            }).catch(error => {
                this.$message.error(error)
            })
        },
    },
    mounted() {
        this.handlerGetStat()
    }
}
</script>
<style lang="scss" scoped>
    .timePicker{
        padding: 0 !important;
    }
</style>