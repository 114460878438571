<template>
    <div class="indexbox">
        <div class="a-fs-16 a-fw-500  a-ff-pmp">快捷功能</div>
        <div class="fastbox a-flex-rfsc a-flex-wrap a-w-100 a-fs-14">
            <div @click="handlerGoDetail(item.url, item.query)" v-for="(item, index) in fastList" :key="index">
                <img :src="item.img" :alt="item.name">
                <span>{{ item.name }}</span>
            </div>
            <!-- <div @click="handlerGoDetail('/project/project-add')">
                <img src="../../../assets/icon/index-fast-cjxm.png" alt="">
                <span>创建项目</span>
            </div>
            <div @click="handlerGoDetail('/station/station-add')">
                <img src="../../../assets/icon/index-fast-cjzd.png" alt="">
                <span>创建站点</span>
            </div>
            <div @click="handlerGoDetail('/staffManage/staff-add')">
                <img src="../../../assets/icon/index-fast-cjyg.png" alt="">
                <span>创建员工</span>
            </div>
            <div @click="handlerGoDetail('/price/price-edit')">
                <img src="../../../assets/icon/index-fast-cjdj.png" alt="">
                <span>创建定价</span>
            </div> -->
            <!-- <div>
                <img src="../../../assets/icon/index-fast-dcdyzd.png" alt="">
                <span>导出当月账单</span>
            </div>
            <div>
                <img src="../../../assets/icon/index-fast-dcdydfzd.png" alt="">
                <span>导出当月电费账单</span>
            </div>
            <div>
                <img src="../../../assets/icon/index-fast-dcdyjms.png" alt="">
                <span>导出当月加盟商</span>
            </div> -->
            <!-- <div @click="handlerGoDetail('/businessCenter/withdrawal', { userBalance: balance })">
                <img src="../../../assets/icon/index-fast-tx.png" alt="">
                <span>提现</span>
            </div> -->
        </div>

    </div>
</template>
<script>
import {
    mapState,
    mapActions
} from 'vuex';
export default {
    data() {
        return {
            balance: 0,
            fastList: [{
                url: '/franchisee/franchisee-add',
                img: require('../../../assets/icon/index-fast-cjjms.png'),
                name: '创建加盟商',
                title: '加盟商'
            },
            {
                url: '/project/project-add',
                img: require('../../../assets/icon/index-fast-cjxm.png'),
                name: '创建项目',
                title: '项目'
            },
            {
                url: '/station/station-add',
                img: require('../../../assets/icon/index-fast-cjzd.png'),
                name: '创建站点',
                title: '站点'
            },
            {
                url: '/staffManage/staff-add',
                img: require('../../../assets/icon/index-fast-cjyg.png'),
                name: '创建员工',
                title: '员工'
            },
            {
                url: '/price/price-edit',
                img: require('../../../assets/icon/index-fast-cjdj.png'),
                name: '创建定价',
                title: '定价'
            },
            {
                url: '/businessCenter/withdrawal',
                img: require('../../../assets/icon/index-fast-tx.png'),
                name: '提现',
                query: { 'userBalance': sessionStorage.getItem('userBalance') || 0 },
                title: '提现'

            }
            ]
        }
    },
    methods: {
        handlerGoDetail(gourl, queryObj) {

            this.$router.push({
                path: gourl,
                query: queryObj

            })
        }
    },
    computed: {
        ...mapState({
            menu: state => state.menu.menu,
        })
    },
    mounted() {
        let a = []
        this.menu.forEach(item => {
            this.fastList.map(oitem => {
                if (item.name.indexOf(oitem.title) != -1) {
                    a.push(oitem)
                }
            })
        })

        this.fastList = a
        // let serverList = [];
        // let forServer = (arr) => {
        //     arr.forEach(item => {
        //         serverList.push(item.path)
        //         if (item.children.length) {
        //             forServer(item.children)
        //         }
        //     })
        // }
        // forServer(this.menu)
        // let fasts = this.fastList.filter(item => {
        //     return serverList.find(it => item.url === it)
        // })
        // this.fastList = fasts

    }
}
</script>
<style lang="scss" scoped>
.fastbox {
    padding: 0;

    font-family: "苹方-简 常规体";

    >div {
        position: relative;
        cursor: pointer;
        width: 25%;
        margin: 32px 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        >img {
            height: 48px;
            width: 48px;
        }

        >span {
            margin-top: 8px;
        }

        &:hover {
            &:after {
                content: '';
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.2);
                top: 0;
                left: 0;
                position: absolute;
                z-index: 10;
            }
        }

    }
}
</style>