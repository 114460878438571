<template>
    <div class="indexbox mapbox a-h-100">
        <!-- <le-input-icon-search label="" v-model="searchStation" placeholder="搜索充电桩点位" large
            style="margin-left:-20px;padding-bottom:16px;padding-top:0;" /> -->
        <el-autocomplete v-model="stationName" :fetch-suggestions="querySearchAsync" placeholder="搜索充电桩点位"
            @select="handleSelect" prefix-icon="el-icon-search" style="padding-bottom:16px;width: 300px;"
            :highlight-fist-item="true" clearable>
        </el-autocomplete>
        <div class="mapContent" id="sceneCheckMap"></div>
        <div class="mapCoverbox a-fs-14 a-flex-rsbc" v-if="contentName && contentName != '您的位置'"
            @click="handlerGoToDetail(buildId)">
            <span class="">{{ contentName }}</span>
            <span>{{ stationChargePlan }}</span>
        </div>
        <div class="mapPointbox a-flex-ccc" @click="moveToCenter">
            <img src="../../../assets/icon/index-map-point.png" alt="">
        </div>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
export default {
    data() {
        return {
            stationChargePlan: '',
            stationName: '',
            stationId: '',
            searchStation: '',
            mapContent: null,
            marker: null,
            mapPoints: [],//地图点位   
            mycenter: null,
            contentName: null,
            buildId: null,
            timeout: null,
            selectItem: null
        }
    },
    props: {
        surveyAreaId: {
            type: String,
            default: "",
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        })
    },
    mounted() {
        this.initMap()
        //this.getSurveyStation()
        this.getMapList()
        // this.$nextTick(() => {
        //     this.initMap()
        //     //this.getSurveyStation()
        //     this.getMapList()
        // })

    },
    beforeDestroy() {
        if (this.marker) {
            this.marker.off("mouseover", () => { });
            this.marker.off("mouseout", () => { });
            this.marker.off("click", () => { });
        }

    },
    methods: {
        querySearchAsync(queryString, cb) {

            clearTimeout(this.timeout);
            var results = []
            if (queryString == '') {
                cb(results);
            } else {
                this.$Axios._post({
                    url: this.$Config.apiUrl.searchStation,
                    showLoading: false,
                    params: {
                        pageNumber: 1,
                        pageSize: 1000,
                        stationName: queryString
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        if (res.result.data.list.length) {
                            var result = res.result.data.list;
                            for (let i = 0; i < result.length; i++) {
                                if (result[i].lat && result[i].lng) {
                                    results.push({
                                        value: result[i].stationName,
                                        id: result[i].stationId,
                                        lat: result[i].lat,
                                        lng: result[i].lng,
                                        stationAddress: result[i].stationAddress,
                                        stationChargePlan: result[i].stationChargePlan,
                                    });
                                }

                            }
                            cb(results);
                        } else {
                            results = []
                            cb(results);
                        }

                        ///  this.setMarker()
                    }
                    else {
                        this.$message.error(res.result.message);
                    }
                });

            }
        },
        getSurveyStation() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getSurveyStation,
                method: "get",
                showLoading: false,
                params: {
                    surveyAreaId: this.surveyAreaId
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.mapPoints = res.result.data;

                    this.setMarker()
                }
                else {
                    this.$message.error(res.result.message);
                }
            });
        },
        initMap() {
            this.mycenter = new TMap.LatLng(39.908823, 116.39747)
            let mylat = sessionStorage.getItem('userlat')
            let mylng = sessionStorage.getItem('userlng')
            if (mylat != 'null' && mylng != 'null') {
                // console.log('经纬度2', mylat, mylng)
                this.mycenter = new TMap.LatLng(mylat, mylng)
                // this.mycenter = new TMap.LatLng(36.732513, 117.8171)

            }
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            this.mapContent = new TMap.Map(document.getElementById('sceneCheckMap'), {
                center: this.mycenter,//设置中心点坐标
                zoom: 17,
            });
            this.mapContent.removeControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE);
            this.mapContent.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
            this.mapContent.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);

        },
        setMarker() {
            let geometries = []
            let coords = []

            this.mapPoints.map((item, index) => {
                // console.log(item)
                // console.log('经纬度1', Number(item.lat), Number(item.lng))
                if (!Number(item.lat) || !Number(item.lng)) {
                    return false
                }


                geometries.push({ //点标注数据数组
                    "id": "demo",
                    "styleId": "marker",
                    "position": new TMap.LatLng(Number(item.lat), Number(item.lng)),
                    "properties": {
                        "content": item.stationName,
                        'buildId': item.stationId,
                        'stationChargePlan': item.stationChargePlan
                    },
                })
                coords.push(new TMap.LatLng(Number(item.lat), Number(item.lng)))
                geometries.unshift({
                    "id": "demo",
                    "styleId": "markerCenter",
                    "position": this.mycenter,
                    "properties": {
                        "content": '您的位置'
                    },
                })
            })


            if (this.mapPoints.length) {


                this.marker = new TMap.MultiMarker({
                    id: "marker-layer", //图层id
                    map: this.mapContent,
                    styles: { //点标注的相关样式
                        "marker": new TMap.MarkerStyle({
                            "width": 32,
                            "height": 44,
                            "anchor": { 'x': 16, 'y': 44 },
                            "src": require("@/assets/icon/map-marker-icon.png")
                        }),
                        'markerCenter': new TMap.MarkerStyle({
                            "width": 28,
                            "height": 44,
                            "anchor": { 'x': 16, 'y': 44 },
                            "src": require("@/assets/icon/map-markerCenter-icon.png")
                        })
                    },
                    geometries: geometries
                });

                // 自适应缩放等级 以包含所有的标记点 https://lbs.qq.com/Vis/JavascriptAPI/APIGuide/map/mapState
                let latlngBounds = new TMap.LatLngBounds();
                for (var i = 0, l = coords.length; i < l; i++) {
                    latlngBounds.extend(coords[i]);
                }
                this.mapContent.fitBounds(latlngBounds, {
                    padding: 100 // 自适应边距
                });

                this.mapContent.setCenter(this.mycenter);

                //初始化infoWindow
                let infoWindow = new TMap.InfoWindow({
                    map: this.mapContent,
                    position: this.mycenter,
                    offset: { x: 0, y: -50 } //设置信息窗相对position偏移像素
                });
                infoWindow.close()
                // marker 事件
                this.marker.on('mouseover', (evt) => {

                    infoWindow.open(); //打开信息窗
                    infoWindow.setPosition(evt.geometry.position);//设置信息窗位置
                    infoWindow.setContent(evt.geometry.properties.content);//设置信息窗内容

                    this.$set(this, 'contentName', evt.geometry.properties.content)
                    this.$set(this, 'buildId', evt.geometry.properties.buildId)
                    this.$set(this, 'stationChargePlan', evt.geometry.properties.stationChargePlan)
                })
                this.marker.on('mouseout', () => {
                    setTimeout(() => {
                        infoWindow.close(); //打开信息窗
                    }, 100)
                })

                this.marker.on('click', (evt) => {
                    this.handlerGoToDetail(evt.geometry.properties.buildId)

                })

            }
        },
        getMapList() {
            this.$Axios._post({
                url: this.$Config.apiUrl.searchStation,
                showLoading: false,
                params: {
                    pageNumber: 1,
                    pageSize: 5000,
                    stationName: "",
                    stationId: "",
                }
            }).then(res => {
                if (res.result.code == 0) {
                    if (res.result.data.list.length) {
                        this.mapPoints = res.result.data.list;

                        this.setMarker()
                    }

                }
                else {
                    this.$message.error(res.result.message);
                }
            }).catch((error) => {
                this.$message.error(error);
            })
        },
        handleSelect(item) {
            this.selectItem = item
            const myLatlng = new TMap.LatLng(item.lat, item.lng)
            //初始化infoWindow
            let infoWindow = new TMap.InfoWindow({
                map: this.mapContent,
                position: myLatlng,
                offset: { x: 0, y: -50 } //设置信息窗相对position偏移像素
            });
            // this.contentName = item.value
            this.$set(this, 'contentName', item.value)
            this.$set(this, 'buildId', item.id)
            this.$set(this, 'stationChargePlan', item.stationChargePlan)
            this.mapContent.panTo(myLatlng);
            this.mapContent.setCenter(myLatlng);
            infoWindow.open(); //打开信息窗
            infoWindow.setContent(item.value);//设置信息窗内容
            // setTimeout(() => {
            //     this.mapContent.setZoom(18);
            // }, 500)
            // 
            this.marker.on('mouseover', (evt) => {
                if (evt.geometry.properties.buildId != item.id) {
                    infoWindow.close();
                }
            })
            this.marker.on('mouseout', () => {
                setTimeout(() => {
                    infoWindow.close(); //打开信息窗
                }, 100)
            })

        },
        //跳转站点详情
        handlerGoToDetail(builId) {
            this.$router.push({
                path: '/station/station-detail',
                query: {
                    stationId: builId
                }
            })
        },
        //移动到我的位置
        moveToCenter() {
            this.mapContent.panTo(this.mycenter)
        }
    }
}
</script>

<style lang="scss" scoped>
.mapbox {
    position: relative;
}

.mapContent {
    width: 100%;
    height: 252px;
    z-index: 1;
}

.mapCoverbox {
    cursor: pointer;
    width: 447px;
    height: 40px;
    background: rgba(255, 255, 255, 0.96);
    box-shadow: 0px 4px 16px 0px rgba(139, 147, 167, 0.2500);
    border-radius: 4px;
    padding: 0 16px;
    position: absolute;
    bottom: 32px;
    left: 34px;
    z-index: 100;

    &:hover {
        color: #007AFF;
    }

}

.mapPointbox {
    position: absolute;
    bottom: 32px;
    right: 34px;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.96);
    box-shadow: 0px 4px 16px 0px rgba(139, 147, 167, 0.25);
    border-radius: 4px;
    z-index: 100;

    img {
        width: 22px;
        height: 22px;
    }
}
</style>