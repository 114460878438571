<template>
    <div class="indexbox a-h-100 a-flex-csbsb a-w-100">
        <div class="a-flex-rsbc">
            <div class="a-fs-16 a-fw-500 a-ff-pmp">最新消息</div>
            <le-jumpto-detail url="/news/news-list">
                <span class="a-fs-14 a-c-normal a-cursor-p">查看更多</span>
            </le-jumpto-detail>
        </div>
        <div class="newsbox a-fs-14 a-flex-csbsb a-h-100 a-w-100">
            <template v-if="newsList.length">
                <div
                    class="a-flex-rsbc a-w-100"
                    v-for="(item, index) in newsList"
                    :key="index"
                >
                    <le-jumpto-detail
                        :url="item.pcNotifyPage"
                        :dQuery="JSON.parse(item.pcNotifyPageParam)"
                    >
                        <span class="newListOver">{{
                            item.content
                        }}</span></le-jumpto-detail
                    >

                    <div class="a-c-second a-flex-cfsfs">
                        <span>{{
                            util.formatDateDayjs(item.createTime, "YYYY-MM-DD")
                        }}</span>
                        <span>{{
                            util.formatDateDayjs(item.createTime, "HH:mm:ss")
                        }}</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="noContent a-flex-ccc">
                    <img src="../../../assets/images/noContent.png" alt="" />
                    <span class="a-fs-14 a-c-normal">暂无内容</span>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import util from "../../../utils/index";
export default {
    data() {
        return {
            util: util,
            newsList: [],
        };
    },
    mounted() {
        this.getNewsList();
    },
    methods: {
        getNewsList() {
            this.$Axios
                ._post({
                    url: this.$Config.apiUrl.getNotifyList,
                    showLoading: false,
                    params: {
                        pageNum: 1,
                        pageSize: 4,
                        exportFlag: false,
                        notifyType: "",
                    },
                })
                .then((res) => {
                    console.log(res);
                    let that = this;
                    if (res.result.code == 0) {
                        if (res.result.data && res.result.data.list) {
                            this.newsList = res.result.data.list;
                        }
                    } else {
                        this.$message.error(res.result.msg);
                    }
                })
                .catch((error) => {
                    this.$message.error(error);
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.newsbox {
    margin-top: 32px;

    > div {
        // margin: 16px 0;

        > span {
            // max-width: 250px;
        }
    }

    .noContent {
        img {
            width: 240px;
            height: 184px;
        }
    }
    /deep/ .a-cursor-p {
        flex: 1;
    }
}

.newListOver {
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    width: 100%;
}
</style>
