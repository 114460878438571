<template>
    <div class="indexbox">
        <div class="a-flex-rsbc">
            <div class="a-fs-16 a-fw-500 a-ff-pmp">车库车辆趋势（截止昨日）</div>
            <jd-other-date-range
                style="padding: 0"
                @change="handleDateChange"
                :pickerOptions="pickerOptions" 
                :timeType.sync="timeType"
                valueFormat="yyyy-MM-dd HH:mm:ss"
                :minDate.sync="startTime" 
                :maxDate.sync="endTime">
            </jd-other-date-range>
        </div>
        <le-echarts-line :echartsObj="echartsObj" style="height:258px;width:100%;margin-top:14px;"></le-echarts-line>
    </div>
</template>
<script>
import util from '../../../utils/util.js'
import { mapState, mapActions } from 'vuex';
export default {
    data() {
        return {
            choiceDate: '',
            choiceMonth: '',
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    this.choiceMonth = minDate
                    if (maxDate) {
                        this.choiceDate = ''
                        this.choiceMonth = ''
                    }
                },
                disabledDate: (time) => {
                    if (this.choiceDate) {
                        if(this.timeType == 0){
                            let one = 30 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else if(this.timeType == 1){
                            let minMonth = this.choiceMonth.getMonth(),
                            lastYear = new Date(this.choiceMonth).setMonth(minMonth - 11),
                            nextYear = new Date(this.choiceMonth).setMonth(minMonth + 11);
                            return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf();
                        }
                    }else{
                        return ''
                    }
                },
            },
            timeType: 0,
            startTime: this.$getDay.getTodayBeforeDays(30) + ' 00:00:00',
            endTime: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
            echartsObj: {
                id: 'indexEcharts',
                useMineAxisLabel: true,
                xAxisData: ['2022-09-03', '2022-09-06', '2022-09-09', '2022-09-12', '2022-09-15', '2022-09-18', '2022-09-21', '2022-09-24', '2022-09-27', '2022-09-30',],
                seriesData0: [2000, 2800, 3200, 3200, 3000, 2900, 2800, 2200, 2500, 2700],
                seriesData1: [1900, 2600, 3000, 3100, 2800, 2700, 2600, 2100, 2400, 2600],
                seriesName0: '当前车辆',
                seriesName1: '历史车辆',
				seriesUnit0: '辆',
				seriesUnit1: '辆',
                echartsType: 'energyList',
            },
        }
    },
    computed:{
        ...mapState({
            company: state => state.company.company
        })
    },
    methods: {
        handleDateChange () {
            this.handlerGetStat()
        },
        handlerGetStat() {
            this.$Axios._post({
                url: this.$Config.apiUrl.getBikeTrendsIndex,
                showLoading: false,
                params: {
                    dateType: this.timeType,
                    endDay: this.endTime,
                    startDay: this.startTime,
                    companyId: this.company.id
                }
            }).then((res) => {
                // console.log(res)
                let that = this
                if (res.result.code == 0) {
                    if (res.result.data) {
                        let dateArr
                        if(res.result.data.length){
                            dateArr = res.result.data
                        }else{
                            dateArr = this.$getDay.enumerateDaysBetweenDates(this.startTime,this.endTime).map(item=>{
                                return {
                                    day: item,
                                }
                            })
                        }
                        that.echartsObj.xAxisData = []
                        that.echartsObj.seriesData0 = []
                        that.echartsObj.seriesData1 = []
                        dateArr.map((item, index) => {
                            that.echartsObj.xAxisData.push(item.day)
                            that.echartsObj.seriesData0.push(item.current?item.current:0)
                            that.echartsObj.seriesData1.push(item.history?item.history:0)
                        })

                    }
                } else {
                    this.$message.error(res.result.msg)
                }

            }).catch(error => {
                this.$message.error(error)
            })
        },
    },
    mounted() {
        this.handlerGetStat()
    }
}
</script>
<style lang="scss" scoped>
    .timePicker{
        padding: 0 !important;
    }
</style>