<template>
    <div class="indexbox">
        <div class="a-flex-rsbc">
            <div class="a-fs-16 a-fw-500 a-ff-pmp">营业额/耗电量</div>
            <el-date-picker v-model="time" type="month" placeholder="选择月" @change="getTimeValue" :editable="false"
                :picker-options="pickerNormalOptions"></el-date-picker>
        </div>
        <le-echarts-line :echartsObj="echartsObj" style="height:258px;width:100%;margin-top:14px;"></le-echarts-line>
    </div>
</template>
<script>
import util from '../../../utils/util.js'
export default {
    data() {
        return {
            time: new Date(),
            pickerNormalOptions: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now()
                }
            },
            startTime: '',
            endTime: '',
            echartsObj: {
                id: 'indexEcharts',
                xAxisData: ['2022-09-03', '2022-09-06', '2022-09-09', '2022-09-12', '2022-09-15', '2022-09-18', '2022-09-21', '2022-09-24', '2022-09-27', '2022-09-30',],
                seriesData0: [2000, 2800, 3200, 3200, 3000, 2900, 2800, 2200, 2500, 2700],
                seriesData1: [1900, 2600, 3000, 3100, 2800, 2700, 2600, 2100, 2400, 2600],
				seriesData2: [1900, 2600, 3000, 3100, 2800, 2700, 2600, 2100, 2400, 2600],
                seriesName0: '耗电量',
                seriesName1: '订单数量',
				seriesName2: '订单金额',
				seriesUnit0: '度',
				seriesUnit1: '笔',
				seriesUnit2: '元',
                echartsType: 'energyList',
                yName: "(元/度/笔)"
            },
        }
    },
    methods: {
        //获取营业额数据
        handlerGetStat() {
            this.$Axios._post({
                url: this.$Config.apiUrl.getStatEnergyList,
                showLoading: false,
                params: {
                    "queryStartDate": this.startTime,
                    "queryEndDate": this.endTime,
                    "statIncome": 1
                }
            }).then((res) => {
                // console.log(res)
                let that = this
                if (res.result.code == 0) {
                    if (res.result.data) {
                        // console.log(res.result.data)
                        that.echartsObj.xAxisData = []
                        that.echartsObj.seriesData0 = []
                        that.echartsObj.seriesData1 = []
						that.echartsObj.seriesData2 = []
                        res.result.data.map((item, index) => {
                            that.echartsObj.xAxisData.push(item['fullDateText'])
                            that.echartsObj.seriesData0.push((item['energy']).toFixed(1))
                            that.echartsObj.seriesData1.push(item['orderNum'])
							that.echartsObj.seriesData2.push(item['orderAmount']/100)
                        })

                    }
                } else {
                    this.$message.error(res.result.msg)
                }

            }).catch(error => {
                this.$message.error(error)
            })
        },
        getTimeValue(time) {
            var date = time ? time : new Date()
            var y = date.getFullYear(), m = date.getMonth();
            this.startTime = util.easyformatDate(new Date(y, m, 1))

            this.endTime = util.easyformatDate(new Date(y, m + 1, 0));
            this.handlerGetStat()


        },
    },
    mounted() {
        this.getTimeValue()
    }
}
</script>
<style lang="scss" scoped>
</style>